













































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { AlarmRuleEntityModel, AlarmRuleQueryModel } from '@common-src/entity-model/alarm-rule-entity';
import AlarmRuleService from '@common-src/service/alarm-rule';

@Component
export default class AlarmRuleListComponent extends TableDialogFormComponent<AlarmRuleEntityModel, AlarmRuleQueryModel> {
    AlarmRuleEntityModel = AlarmRuleEntityModel;
    created() {
        this.initTable({
            service: AlarmRuleService,
            queryModel: new AlarmRuleQueryModel(),
            tableColumns: AlarmRuleEntityModel.getTableColumns()
        });
        this.getList();
    }

    enableClick(model: AlarmRuleEntityModel) {
        const enable = !model.enable;
        AlarmRuleService.enable(model.id, enable).then(() => {
            model.enable = !model.enable;
        });
    }
}

