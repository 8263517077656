
import { AlarmRuleEntityModel, AlarmRuleQueryModel } from '@common-src/entity-model/alarm-rule-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';

const URL_PATH = `${RULE_BASE_REQUEST_PATH}/rule/event`;

class AlarmRuleService implements ICRUDQ<AlarmRuleEntityModel, AlarmRuleQueryModel> {
    async create(model: AlarmRuleEntityModel):Promise<AlarmRuleEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<AlarmRuleEntityModel> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return new AlarmRuleEntityModel().toModel(res);
    }

    async update(model: AlarmRuleEntityModel):Promise<AlarmRuleEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const params = model.toService();
        const res = await put(url, params);
        return res;
    }

    async delete(model: AlarmRuleEntityModel):Promise<AlarmRuleEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: AlarmRuleQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/query`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new AlarmRuleEntityModel().toModel(item));
        return res;
    }

    async enable(id: string, enable: boolean):Promise<AlarmRuleEntityModel> {
        const url = `${URL_PATH}/enable`;
        const res = await put(url, { id, enable });
        return res;
    }
}

export default new AlarmRuleService();
